import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/workspace/src/components/layouts/MDXLayout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const WarningBlock = makeShortcode("WarningBlock");
const MyImage = makeShortcode("MyImage");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <WarningBlock mdxType="WarningBlock">CodeStory is currently in private beta.<br /> A login is required to access the platform.</WarningBlock>
    <h3 {...{
      "id": "sign-up-with-your-github-account",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#sign-up-with-your-github-account",
        "aria-label": "sign up with your github account permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`Sign up with your GitHub account`}</h3>
    <p>{`Sign up at `}<a parentName="p" {...{
        "href": "https://console.codestory.dev/"
      }}>{`codestory.dev website`}</a>{`.`}</p>
    <p>{`This is an OAuth authentication. CodeStory servers do `}<strong parentName="p">{`not`}</strong>{` need to access your repositories, which can be hosted anywhere.`}</p>
    <h3 {...{
      "id": "create-a-project",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#create-a-project",
        "aria-label": "create a project permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`Create a project`}</h3>
    <div className="grid grid-cols-2 gap-1">
  <MyImage src="Getting_started_1.png" alt="" mdxType="MyImage" />
  <MyImage src="Getting_started_2.png" alt="" mdxType="MyImage" />
    </div>
    <p>{`Wait until the project is installed.`}</p>
    <MyImage src="Getting_started_3.png" alt="" mdxType="MyImage" />
    <h3 {...{
      "id": "download-codestory-app",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#download-codestory-app",
        "aria-label": "download codestory app permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`Download CodeStory app`}</h3>
    <a href="https://console.codestory.dev/downloads/">Download the CodeStory app</a> and install it.
    <h3 {...{
      "id": "sign-in-from-the-codestory-app",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#sign-in-from-the-codestory-app",
        "aria-label": "sign in from the codestory app permalink",
        "className": "anchor before"
      }}><undefined parentName="a"></undefined></a>{`Sign in from the CodeStory app`}</h3>
    <MyImage src="Getting_started_Login.png" alt="Log in" preset="no-border" mdxType="MyImage" />
    <p>{`The projects you own and the projects your are a team member of are shown.`}</p>
    <MyImage src="Getting_started_4.png" alt="Log in" preset="no-border" mdxType="MyImage" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      